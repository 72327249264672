const initialState = {
    sidebarShow:'responsive',
    logo:'',
    default_currency:''
};
 
export const ThemeReducers = (state = initialState, action)=> {
    switch (action.type){        
        case 'set':
        return {
            ...state,sidebarShow:action.payload
        }   
        case 'SetLogo':
            return {
                ...state, logo: action.payload
            } 
        case 'default_currency':
            return {
                ...state, default_currency: action.payload
            }            
        default:
            return state;
    }
}