import React, {createContext, Component} from "react";

export const MainContext = createContext();

class ContextProvider extends Component {

    state = {
        odds:{}    
    };

    setBackLay = (data) => {
        this.setState({
            odds:data
        });
    };

    render() {

        return (

            <MainContext.Provider
                value={{
                    ...this.state,
                    setBackLay: this.setBackLay,
                }}>

                {this.props.children}

            </MainContext.Provider>

        )

    }

}

export default ContextProvider;

export const LoginConsumer = MainContext.Consumer;
