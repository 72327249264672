import moment from 'moment';
import {ADMINLOGIN,ADMINLOGOUT} from './actionTypes';

const initialState = {
    adminLogin:{
        
    },
    sports_id:'',
    series_Id:'',
    match_Id:'',
    loadedAt:0,
    matchData:''
};
 
export const AdminAuthReducer = (state = initialState, action)=> {
    switch (action.type){
        case ADMINLOGIN:
            return {
                ...state,loadedAt:moment().unix(),adminLogin:action.payload
            }
        case ADMINLOGOUT:
        return {
            ...state,loadedAt:0,adminLogin:{}
        }       
        case 'loadedAt':
        return {
            ...state,loadedAt:action.payload
        } 
        case 'SPORTS_ID':
        return {
            ...state,sports_id:action.payload
        } 
        case 'SERIES_ID':
        return {
            ...state,series_Id:action.payload
        } 
        case 'MATCHDATA':
        return {
            ...state,matchData:action.payload
        } 
        case 'RESULTMATCHDATA':
        return {
            ...state,resultMatchData:action.payload
        } 
        case 'MATCH_ID':
            return {
                ...state,match_Id:action.payload
            } 
        
         
        default:
            return state;
    }
}