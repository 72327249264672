import { createStore ,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {AdminAuthReducer} from './AuthReducers';
import {ThemeReducers} from './ThemeReducers';
import { persistStore, persistReducer } from 'redux-persist';
// import storageSession from 'redux-persist/lib/storage/asyncstorage';
import AsyncStorage from '@react-native-community/async-storage';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers } from 'redux';

const rootPersistConfig = {
    key: 'root',
    storage:AsyncStorage,
    blacklist: ['Login']
}
   
const authPersistConfig = {
    key: 'auth',
    storage:AsyncStorage,
    whitelist: ['Login']
}
  
const rootReducer = combineReducers({
    AdminAuth: persistReducer(authPersistConfig, AdminAuthReducer),
    themeReducers: persistReducer(authPersistConfig, ThemeReducers),
})

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
let store = createStore(persistedReducer,composeWithDevTools(applyMiddleware(thunk)));
let persistor = persistStore(store);

export { store, persistor };

// export const vendorStore = createStore(mainReducer,applyMiddleware(thunk));